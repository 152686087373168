<template>
  <div class="tour">
    <div class="top">
      <b-img
        v-if="tourinfo.course_images === 'Y'"
        :class="[splash]"
        :src="
          config.VUE_APP_COURSE_URL +
            this.id +
            '/' +
            tourinfo.course_code +
            '/' +
            tourinfo.course_image_file_1
        "
      >
      </b-img>
      <b-img v-else :src="config.VUE_APP_DEFAULT_COURSE" :class="[splash]">
      </b-img>
      <b-row :class="[splashText]" class="overlay">
        <b-col>
          <h2 class="fullName-width">
            {{ tourinfo.full_name }}
          </h2>
          <h4 class="courseName-width">
            {{ tourinfo.course_name }}
          </h4>
          <br />
        </b-col>
        <b-col>
          <b-img :class="[logo]" :src="tourinfo.sponsor_logo"></b-img>
        </b-col>
      </b-row>
    </div>
    <template v-if="Player === 'guest'">
      <b-row>
        <b-col class="leftCol" style="padding-right:1px;">
          <h2 class="splashPad">
            Your Personal Tournament Info area
          </h2>
          <b-row>
            <b-col class="infoPad">
              <h4 class="underLine">
                Info:
              </h4>
              <h4 class="underLine">
                Entry Fee
              </h4>
              <h3 class="valuesize">
                {{ tourinfo.entry_fee }}
              </h3>
              <h4 class="underLine">
                Entries Close
              </h4>
              <h3 class="valuesize">
                <span v-if="tourinfo.entry_closed === 'N'">
                  {{ tourinfo.closing_date }} - {{ tourinfo.closing_time }}
                </span>
                <span v-else> Entries Closed </span>
              </h3>
            </b-col>
            <b-col>
              <h4 class="underLine">
                Status
              </h4>
              <h3 class="valuesize">
                <span
                  v-if="tourinfo.entry_open === 'Y'"
                  :class="{
                    'entered-text-detail':
                      tourinfo.entry_status == 'E' ||
                      tourinfo.entry_status == 'Q',
                  }"
                  >Entered</span
                ><span
                  :class="{
                    'not-entered-text-detail': tourinfo.entry_status == 'W',
                    'no-entered-rec-detail':
                      tourinfo.entry_status == 'X' ||
                      tourinfo.entry_status == 'P',
                  }"
                  v-else
                >
                  Not Entered
                </span>
              </h3>
            </b-col>
            <b-col>
              <h4 class="underLine">
                Actions
              </h4>
              <template v-if="tourinfo.mosrestrict_ind == 'O'"> </template>
              <template v-else>
                <b-button
                  class="memberButton"
                  v-b-modal.ew
                  variant="success"
                  v-if="tourinfo.entry_action === 'E'"
                >
                  Enter
                </b-button>
                <b-button
                  class="memberButton"
                  v-b-modal.ew
                  variant="danger"
                  v-if="tourinfo.entry_action === 'W'"
                >
                  Withdraw
                </b-button>
                <b-button
                  class="reportTourBtn"
                  v-if="tourinfo.entry_action === 'T'"
                >
                  Call to Enter
                </b-button>
                <b-button
                  class="memberButton"
                  variant="success"
                  v-if="
                    tourinfo.checkin_status == 'N' &&
                      tourinfo.checkin_open == 'Y'
                  "
                >
                  Check-in
                </b-button>
                <b-button
                  class="memberButton"
                  variant="success"
                  v-if="
                    (tourinfo.payment_allowed == 'Y' &&
                      tourinfo.fee_paid == 'N' &&
                      tourinfo.entry_status == 'E') ||
                      (tourinfo.entry_status == 'Q' &&
                        tourinfo.entry_feeowe > 0)
                  "
                >
                  Pay
                </b-button>
              </template>
              <b-modal id="ew" :title="tourinfo.full_name" hide-footer>
                <b-row>
                  <b-col>
                    <template v-if="tourinfo.entry_action === 'E'">
                      <b-form @submit="onEnter">
                        <b-form-group
                          id="input-group-2"
                          label="Entry"
                          label-for="input-2"
                        >
                          <b-form-textarea
                            id="input-2"
                            v-model="form.text"
                            placeholder="Entry Notes"
                            maxlength="300"
                            rows="6"
                            required
                          ></b-form-textarea>
                        </b-form-group>
                        <p>
                          By clicking Confirm you are agreeing to the Terms and
                          Conditions which can be found in your players
                          handbook.
                        </p>
                        <b-button type="submit" variant="success"
                          >Enter</b-button
                        >
                      </b-form>
                    </template>
                    <template v-if="tourinfo.entry_action === 'W'">
                      <b-form @submit="onWithdraw">
                        <b-form-group
                          id="input-group-2"
                          label="Withdraw"
                          label-for="input-2"
                        >
                          <b-form-textarea
                            id="input-2"
                            v-model="form.text"
                            placeholder="Withdraw Reason"
                            maxlength="300"
                            rows="6"
                            required
                          ></b-form-textarea>
                        </b-form-group>
                        <p>
                          By clicking Confirm you are agreeing to the Terms and
                          Conditions which can be found in your players
                          handbook.
                        </p>
                        <b-button type="submit" variant="danger"
                          >Withdraw</b-button
                        >
                      </b-form>
                    </template>
                  </b-col>
                </b-row>
              </b-modal>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </template>
    <div>
      <b-row>
        <b-col lg="6" md="12" class="leftCol" style="padding-right:1px;">
          <h2 class="splashPad">
            Tournament Info
          </h2>
          <b-row>
            <b-col>
              <b-row>
                <b-col class="infoPad">
                  <h4 class="underLine">
                    Dates:
                  </h4>
                  <h3 class="valuesize">
                    {{ tourinfo.dates }}
                  </h3>
                </b-col>
              </b-row>
              <b-row class="rowPad">
                <b-col class="infoPad">
                  <h4>
                    <span class="underLine">Rounds:</span>
                  </h4>
                  <h3 class="valuesize">
                    {{ tourinfo.rounds }}
                  </h3>
                </b-col>
              </b-row>
              <b-row class="rowPad">
                <b-col class="infoPad">
                  <h4>
                    <span class="underLine">Par</span>
                  </h4>
                  <h3 class="valuesize">
                    {{ tourinfo.course_total_par }}
                  </h3>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="infoPad">
              <b-row>
                <b-col
                  v-if="
                    Object.keys(
                      tourinfo.previous_winners.previous_winners_entry.cc_code_1
                    ).length !== 0
                  "
                >
                  <h4 class="underLine">
                    Previous Winner
                  </h4>
                  <p
                    v-if="
                      Object.keys(
                        tourinfo.previous_winners.previous_winners_entry
                          .cc_name_1
                      ).length === 0
                    "
                  >
                    -
                  </p>
                  <p v-else>
                    {{
                      tourinfo.previous_winners.previous_winners_entry.cc_name_1
                    }}
                  </p>
                </b-col>
                <b-col v-if="tourinfo.winner_found === 'Y'">
                  <h4 class="underLine">
                    Winner
                  </h4>
                  <p>
                    {{ tourinfo.winner_name }}
                  </p>
                </b-col>
                <b-col v-else> </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <h4 class="underLine">
                    Reports:
                  </h4>
                  <b-dropdown
                    v-if="Array.isArray(tourinfo.reports.reports_entry)"
                    text="Please Select a Report"
                    class="dropdownReports"
                  >
                    <b-dropdown-item
                      v-for="(reports, index) in tourinfo.reports.reports_entry"
                      :key="index"
                      :title="reports.report_url"
                      :value="reports.report_title"
                      class="option"
                      :to="{
                        name: 'reports',
                        query: {
                            url: reports.report_url,
                            id: id,
                            code: tourinfo.code,
                            title: reports.report_title,
                        },
                      }"
                      >{{ reports.report_title }}</b-dropdown-item
                    >
                  </b-dropdown>
                  <b-dropdown
                    v-else
                    text="Please Select a Report"
                    class="dropdownReports"
                  >
                    <b-dropdown-item
                      v-for="(reports, index) in tourinfo.reports"
                      :key="index"
                      :text="title"
                      :value="reports.report_title"
                      class="option"
                      :to="{
                        name: 'reports',
                        query: {
                          url: reports.report_url,
                          id: id,
                          code: tourinfo.code,
                          title: reports.report_title,
                        },
                      }"
                      >{{ reports.report_title }}</b-dropdown-item
                    >
                  </b-dropdown>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="6" md="12" class="leftCol " style="padding-left:1px;">
          <h2 class="splashPad">
            About the Course
          </h2>
          <b-row>
            <b-col lg="6" class="infoPad">
              <h4 class="underLine">
                Course Name:
              </h4>
              <h3 class="valuesize rightside-changes">
                {{ tourinfo.course_name }}
              </h3>
              <h4 class="underLine">
                City:
              </h4>
              <h3 class="valuesize rightside-changes">
                {{ tourinfo.course_city }}
              </h3>
              <h4 class="underLine">
                Country:
              </h4>
              <h3 class="valuesize rightside-changes">
                {{ tourinfo.course_country }}
              </h3>
            </b-col>
            <b-col>
              <h4 class="underLine">
                Telephone Neumber:
              </h4>
              <h3 class="valuesize rightside-changes">
                <a
                  :href="'tel:' + tourinfo.course_telephone"
                  rel="noopener"
                  target="_blank"
                >
                  {{ tourinfo.course_telephone }}
                </a>
              </h3>
              <h4 class="underLine">
                Email:
              </h4>
              <h3 class="valuesize rightside-changes">
                <a
                  :href="'mailto:' + tourinfo.course_email"
                  rel="noopener"
                  target="_blank"
                >
                  {{ tourinfo.course_email }}
                </a>
              </h3>
              <h4 class="underLine">
                Website:
              </h4>
              <h3 class="valuesize rightside-changes">
                <a
                  :href="'https://' + tourinfo.course_website"
                  rel="noopener"
                  target="_blank"
                >
                  {{ tourinfo.course_website }}
                </a>
              </h3>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="desc">
          <p v-if="Object.keys(tourinfo.course_para1).length === 0"></p>
          <p v-else>
            {{ tourinfo.course_para1 }}
          </p>
          <p v-if="Object.keys(tourinfo.course_para2).length === 0"></p>
          <p v-else>
            {{ tourinfo.course_para2 }}
          </p>
          <p v-if="Object.keys(tourinfo.course_para3).length === 0"></p>
          <p v-else>
            {{ tourinfo.course_para3 }}
          </p>
          <p v-if="Object.keys(tourinfo.course_para4).length === 0"></p>
          <p v-else>
            {{ tourinfo.course_para4 }}
          </p>
        </b-col>
        <b-col class="desc" v-if="tourinfo.course_code === 'TBC'"> </b-col>
        <b-col lg="3" md="12" class="desc" v-else>
          <div class="mapouter">
            <div class="gmap_canvas">
              <iframe
                id="gmap_canvas"
                :src="
                  config.VUE_APP_MAPS_URL +
                    tourinfo.course_name +
                    '&t=&z=13&ie=UTF8&iwloc=&output=embed'
                "
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              ></iframe
              ><a href="https://yt2.org" rel="noopener" target="_blank"></a
              ><br />
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-row :class="courseStats">
        <b-col lg="6" md="12" style="padding-right:0px;">
          <table class="courseinfo">
            <thead>
              <tr>
                <th>
                  Hole
                </th>
                <th>
                  1
                </th>
                <th>
                  2
                </th>
                <th>
                  3
                </th>
                <th>
                  4
                </th>
                <th>
                  5
                </th>
                <th>
                  6
                </th>
                <th>
                  7
                </th>
                <th>
                  8
                </th>
                <th>
                  9
                </th>
                <th>
                  out
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Par
                </td>
                <td
                  v-for="number in tourinfo.course_par.split(',').slice(0, 9)"
                  :key="number"
                >
                  {{ number }}
                </td>
                <td>
                  {{ tourinfo.course_out_par.slice(1, 5) }}
                </td>
              </tr>
              <tr>
                <td>
                  Yards
                </td>
                <td
                  v-for="number in tourinfo.course_length_yards
                    .split(',')
                    .slice(0, 9)"
                  :key="number"
                >
                  {{ number }}
                </td>
                <td>
                  {{ outVal(tourinfo.course_length_yards) }}
                </td>
              </tr>
              <tr>
                <td>
                  Meters
                </td>
                <td
                  v-for="number in tourinfo.course_length_meters
                    .split(',')
                    .slice(0, 9)"
                  :key="number"
                >
                  {{ number }}
                </td>
                <td>
                  {{ outVal(tourinfo.course_length_meters) }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
        <b-col lg="6" md="12" style="padding-left:0px;">
          <table class="courseinfo">
            <thead>
              <tr>
                <th>
                  10
                </th>
                <th>
                  11
                </th>
                <th>
                  12
                </th>
                <th>
                  13
                </th>
                <th>
                  14
                </th>
                <th>
                  15
                </th>
                <th>
                  16
                </th>
                <th>
                  17
                </th>
                <th>
                  18
                </th>
                <th>
                  In
                </th>
                <th>
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  v-for="number in tourinfo.course_par.split(',').slice(9, 18)"
                  :key="number"
                >
                  {{ number }}
                </td>
                <td>
                  {{ tourinfo.course_in_par.slice(1, 5) }}
                </td>
                <td>
                  {{ tourinfo.course_total_par }}
                </td>
              </tr>
              <tr>
                <td
                  v-for="number in tourinfo.course_length_yards
                    .split(',')
                    .slice(9, 18)"
                  :key="number"
                >
                  {{ number }}
                </td>
                <td>
                  {{ inVal(tourinfo.course_length_yards) }}
                </td>
                <td>
                  {{ totalVal(tourinfo.course_length_yards) }}
                </td>
              </tr>
              <tr>
                <td
                  v-for="number in tourinfo.course_length_meters
                    .split(',')
                    .slice(9, 18)"
                  :key="number"
                >
                  {{ number }}
                </td>
                <td>
                  {{ inVal(tourinfo.course_length_meters) }}
                </td>
                <td>
                  {{ totalVal(tourinfo.course_length_meters) }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Tournament",
  props: ["course_code", "id", "code", "config", "log"],
  data() {
    return {
      tourinfo: [],
      splash: "splash",
      splashText: "splashText",
      logo: "logo",
      overlay: "overlay",
      courseStats: "courseStats",
    };
  },
  computed:{
    loggedIn(Player){
      if (this.log === '' || this.log.status === null){
        Player = 'guest'
      }
      else {Player = this.log.playerrID}
      return Player
    }
  
  },
  methods: {
    outVal(value) {
      var split = value.split(",");
      var front9 = split.slice(0, 9);
      for (var i = 0; i < front9.length; i++) front9[i] = +front9[i];
      var sum = front9.reduce(function(a, b) {
        return a + b;
      }, 0);
      return sum;
    },
    inVal(value) {
      var split = value.split(",");
      var back9 = split.slice(9, 18);
      for (var i = 0; i < back9.length; i++) back9[i] = +back9[i];
      var sum = back9.reduce(function(a, b) {
        return a + b;
      }, 0);
      return sum;
    },
    totalVal(value) {
      var split = value.split(",");
      for (var i = 0; i < split.length; i++) split[i] = +split[i];
      var sum = split.reduce(function(a, b) {
        return a + b;
      }, 0);
      return sum;
    },
  },
  mounted() {
    if (this.log === "") {
      axios //add API Call
        .get(
          process.env.VUE_APP_API +
            "inc/xsjs.php?type=xml2json&url=" +
            process.env.VUE_APP_TIC_BASE +
            "tourn=" +
            this.course_code +
            "~season=" +
            this.id +
            "~jsout=x~xsl=~?randomadd=" +
            new Date().getTime()
        ) // Page number changed on each page based on JSON page id you are calling
        .then((response) => (this.tourinfo = response.data));
    } else {
      axios
        .get(
          process.env.VUE_APP_API +
            "inc/xsjs.php?type=xml2json&url=" +
            process.env.VUE_APP_TIC_BASE +
            "tourn=" +
            this.course_code +
            "~player=" +
            this.log.playerID +
            "~tourns=y~seasons=y~jsout=x~xsl=~?randomadd=" +
            new Date().getTime()
        )
        .then((response) => (this.tourinfo = response.data));
    }
  },
};
</script>

<style scoped>
.top {
  height: 395px;
  overflow: hidden;
}
.tour {
  padding-top: 20px;
}
.rightside-changes {
  margin-bottom: 20px;
}
h3.valuesize {
  font-size: 24px;
}
h4.courseName-width {
  font-size: 18px;
}
h2.fullName-width {
  width: 400px;
}
.splash {
  width: 100%;
}
.leftCol > .row {
  padding-left: 15px;
  padding-right: 15px;
}
.underLine {
  /* text-decoration: underline; */
  padding-top: 10px;
  font-size: 18px;
}
.dropdownReports {
  padding-top: 10px;
}
::v-deep .dropdownReports > .btn {
  background-color: #fff;
  color: black;
  font-size: 14px;
}
.courseinfo {
  width: 100%;
}
.option > a {
  font-weight: normal;
  display: block;
  white-space: nowrap;
  min-height: 1.2em;
  padding: 0px 2px 1px;
}
.valuesize > a {
  color: #860038;
}
.splashText {
  position: absolute;
  top: 230px;
  color: white;
}
.infoPad {
  padding: 10px 30px;
}
.logo {
  max-width: 126px;
  margin-left: -45%;
  padding-top: 5%;
}

.overlay {
  background-color: rgba(134, 0, 56, 0.7);
  padding-left: 4%;
  padding-top: 2%;
}

.splashPad {
  text-align: center;
  color: white;
  background-color: #004812;
  padding-top: 5px;
  padding-bottom: 3px;
}

.MainInfo {
  padding-left: calc(3vw + 3vh);
  padding-top: calc(2vw + 2vh);
  padding-right: calc(3vw + 3vh);
  padding-bottom: calc(2vw + 2vh);
}

.courseStats {
  padding: 10px;
}

.rowPad {
  padding-bottom: 1%;
}
tr:nth-of-type(odd) {
  background-color: #dfe1e6;
}
thead > tr {
  background-color: white !important;
  font-weight: bold;
  font-size: 16px !important;
}

td {
  padding-top: 6px !important;
  padding-right: 6px !important;
  padding-bottom: 6px !important;
  vertical-align: middle;
  font-size: 16px !important;
}
.reportButton {
  font-size: calc(0.5vh + 0.5vw);
}

.courseStats > .col:first-child {
  padding-right: 0;
}
.courseStats > .col:last-child {
  padding-left: 0;
}
.mainImg {
  max-width: 75%;
}

#gmap_canvas {
  height: 300px;
  width: 300px;
}
.desc {
  font-size: 14px;
  padding: 35px;
}
.desc > p {
  font-weight: 400;
  font-size: 1.3125rem;
  line-height: 2rem;
  -webkit-font-smoothing: antialiased;
}
.no-entered-rec-detail {
  border-color: #e6b500;
  background-color: #ffc900;
  color: #fff !important;
  padding-top: 2px !important;
  padding-right: 5px !important;
  padding-bottom: 2px !important;
  padding-left: 5px !important;
  border-radius: 4px !important;
  float: none !important;
  display: inline-block;
}
.entered-text-detail {
  border-color: #28a54c;
  background-color: #33cd5f;
  color: #fff !important;
  padding-top: 2px !important;
  padding-right: 5px !important;
  padding-bottom: 2px !important;
  padding-left: 5px !important;
  border-radius: 4px !important;
  float: none !important;
  display: inline-block;
}
@media only screen and (max-width: 1024px) {
  #gmap_canvas {
    height: 250px;
    width: 200px;
  }
  .splash {
    height: 300px;
  }
  .top {
    height: 300px;
  }

  .logo {
    margin-left: -30%;
    padding-top: 20%;
  }
  .splashPad {
    padding-top: 8%;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 18px;
  }
}
@media only screen and (max-width: 768px) {
  #gmap_canvas {
    height: 250px;
    width: 300px;
  }
  .splashPad {
    padding-top: 2%;
  }
}
@media only screen and (max-width: 480px) {
  .splash {
    height: 175px;
  }
  .top {
    height: 500px;
  }
  .splashText {
    margin-top: 0;
    text-align: center;
  }
  .overlay {
    flex-basis: auto;
    background-color: #263056;
    color: white;
  }
  .logo {
    width: 50%;
    padding-top: 5%;
    padding-bottom: 5%;
    margin-left: 0;
  }
  h2 {
    font-size: 1.7em;
  }
  h3 {
    font-size: 1.5em;
  }
  h4 {
    font-size: 1.3em;
  }
  .reportButton {
    font-size: 1em;
  }
  .reportRow {
    padding-bottom: 10%;
  }
}
</style>
