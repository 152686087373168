<template>
  <div id="reportTable" class="table-responsive">
    <div class="mainTitle">
      <h3 class="tourHead">{{ data.short_name }} - {{ title }}</h3>
      <h3 class="tourDates">
        {{ data.course_dates }}
      </h3>
      <br />
    </div>
    <table>
      <thead>
        <tr>
          <template v-if="tablePick === 'Entries'">
            <th>
              No
            </th>
            <th>
              Player(s)
            </th>
            <th>
              Country
            </th>
            <th>
              Entry Status
            </th>
            <th>
              Exempt Rank
            </th>
            <th v-if="config.VUE_APP_PLAYER_ICON === 'Y'"></th>
          </template>
          <template v-if="tablePick === 'Draw'">
            <th v-if="data.match_play === 'Y'">
              Group
            </th>
            <th v-else>
              Match
            </th>
            <th>
              Time
            </th>
            <th v-if="data.multi_course === 'Y'">
              Course
            </th>
            <th>
              Tee
            </th>
            <th>
              Player(s)
            </th>
          </template>
          <template
            v-if="
              (tablePick === 'Score' && data.match_play === 'N') ||
                (tablePick === 'Live' && data.match_play === 'N')
            "
          >
            <th>
              Pos
            </th>
            <th>
              Players(s)
            </th>
            <th>
              Country
            </th>
            <th>
              Par
            </th>
            <template v-if="tablePick === 'Score'">
              <th v-for="(i, roundIt) in range(1, roundsPlayed)" :key="roundIt">
                <span v-if="roundsPlayed === 1 && tablePick === 'Score'">
                  Score
                </span>
                <span v-else> R{{ i }} </span>
              </th>
              <th v-if="roundsPlayed > 1 && tablePick === 'Score'">
                Score
              </th>
              <th v-if="config.VUE_APP_PLAYER_ICON === 'Y'"></th>
            </template>
            <template v-if="tablePick === 'Live'">
              <th>
                Thru
              </th>
            </template>
          </template>
          <template
            v-if="
              (tablePick === 'Score' && data.match_play === 'Y') ||
                (tablePick === 'Live' && data.match_play === 'Y')
            "
          >
            <th>
              Name
            </th>
            <th>
              Score
            </th>
            <th>
              Thru.
            </th>
            <th>
              Score
            </th>
            <th>
              Name
            </th>
          </template>
          <template> </template>
          <template v-if="tablePick === 'Final'">
            <th>
              Pos
            </th>

            <th>
              Players(s)
            </th>
            <th>
              Country
            </th>
            <th>
              Winnings
            </th>
            <template v-if="match === 'N'">
              <th>
                Par
              </th>
              <th>
                Score
              </th>
              <th v-for="(i, roundIt) in range(1, roundsPlayed)" :key="roundIt">
                R{{ i }}
              </th>
              <th v-if="config.VUE_APP_PLAYER_ICON === 'Y'"></th>
            </template>
          </template>
        </tr>
      </thead>
      <tbody>
        <template v-for="(tabledata, index) in Eds">
          <tr @click.stop="rowClicked(index)" :key="index">
            <template v-if="tablePick === 'Entries'">
              <td
                class="posnum"
                v-if="
                  tabledata.playerno !== 'Cat' && tabledata.playerno !== 'EOF'
                "
              >
                {{ tabledata.serial_no }}
              </td>
              <td colspan="11" class="catD" v-if="tabledata.playerno === 'Cat'">
                {{ tabledata.exempt_desc }}
              </td>
              <td colspan="11" class="cut" v-if="tabledata.playerno === 'EOF'">
                {{ tabledata.exempt_desc }}
              </td>

              <td
                v-if="
                  tabledata.playerno !== 'Cat' && tabledata.playerno !== 'EOF'
                "
              >
                {{ tabledata.playing_name }}
              </td>
              <td
                class="mobHide"
                v-if="
                  tabledata.pos !== 'CUT' &&
                    tabledata.playerno !== 'Cat' &&
                    tabledata.playerno !== 'EOF'
                "
              >
                <span v-if="tabledata.nationality === ''"></span>
                <span v-else>
                  <img
                    class="flag"
                    :src="
                      (config.VUE_APP_FLAG_URL + tabledata.nationality)
                        | lowercase
                    "
                  />
                </span>
              </td>
              <td
                v-if="
                  tabledata.playerno !== 'Cat' && tabledata.playerno !== 'EOF'
                "
              >
                {{ tabledata.status_text }}
              </td>
              <td
                v-if="
                  tabledata.playerno !== 'Cat' && tabledata.playerno !== 'EOF'
                "
              >
                {{ tabledata.exempt_rank }}
              </td>
              <td
                v-if="
                  tabledata.playerno !== 'Cat' &&
                    tabledata.playerno !== 'EOF' &&
                    config.VUE_APP_PLAYER_ICON === 'Y'
                "
              >
                <a :href="'/#/playerprofile/' + tabledata.playerno">
                  <font-awesome-icon
                    style="color:#337ab7!important;"
                    id="user"
                    class="icon"
                    :icon="['fa', 'user']"
                  />
                </a>
              </td>
            </template>
            <template v-if="tablePick === 'Draw' && data.match_play === 'N'">
              <td
                class="posnum drawPos"
                :class="{
                  odd: tabledata.match % 2 === 1,
                  even: tabledata.match % 2 === 0,
                }"
                v-if="tabledata.place == 1"
              >
                <span>
                  {{ tabledata.match }}
                </span>
              </td>
              <td
                :class="{
                  odd: tabledata.match % 2 === 1,
                  even: tabledata.match % 2 === 0,
                }"
                v-else
                class="noPos"
              >
                <span>.</span>
              </td>
              <td
                :class="{
                  odd: tabledata.match % 2 === 1,
                  even: tabledata.match % 2 === 0,
                }"
                v-if="tabledata.place == 1"
              >
                {{ tabledata.time }}
              </td>
              <td
                :class="{
                  odd: tabledata.match % 2 === 1,
                  even: tabledata.match % 2 === 0,
                }"
                v-else
              ></td>
              <template v-if="data.multi_course === 'Y'">
                <td
                  :class="{
                    odd: tabledata.match % 2 === 1,
                    even: tabledata.match % 2 === 0,
                  }"
                  v-if="tabledata.place == 1"
                >
                  {{ tabledata.course }}
                </td>
                <td
                  :class="{
                    odd: tabledata.match % 2 === 1,
                    even: tabledata.match % 2 === 0,
                  }"
                  v-else
                ></td>
              </template>
              <td
                :class="{
                  odd: tabledata.match % 2 === 1,
                  even: tabledata.match % 2 === 0,
                }"
                v-if="tabledata.place == 1"
              >
                {{ tabledata.tee }}
              </td>
              <td
                :class="{
                  odd: tabledata.match % 2 === 1,
                  even: tabledata.match % 2 === 0,
                }"
                v-else
              ></td>
              <td
                class="drawName"
                :class="{
                  odd: tabledata.match % 2 === 1,
                  even: tabledata.match % 2 === 0,
                }"
              >

                  {{ tabledata.name }}
              </td>
            </template>
            <template v-if="tablePick === 'Draw' && data.match_play === 'Y'">
              <td>
                {{ tabledata.match }}
              </td>
              <td>
                {{ tabledata.time }}
              </td>
              <td>
                {{ tabledata.tee }}
              </td>
              <td>
                <p>
                  {{ tabledata.name_1 }}
                </p>
                <p>
                  {{ tabledata.name_2 }}
                </p>
              </td>
            </template>
            <template
              v-if="
                (tablePick === 'Score' && data.match_play === 'N') ||
                  (tablePick === 'Live' && data.match_play === 'N')
              "
            >
              <td class="cut" colspan="11" v-if="tabledata.pos === 'CUT'">
                {{ tabledata.cut_round }}
              </td>
              <td
                class="posnum"
                v-else-if="
                  index !== 0 &&
                    (tabledata.tied !== '=' ||
                      Eds[index - 1].pos !== tabledata.pos)
                "
              >
                <span>
                  {{ tabledata.pos }}
                </span>
              </td>
              <td class="posnum" v-else-if="index === 0">
                <span>
                  {{ tabledata.pos }}
                </span>
              </td>
              <td v-else class="noPos"><span>.</span></td>

              <td
                v-if="
                  tabledata.playerno !== 'Cat' &&
                    tabledata.pos !== 'CUT' &&
                    team === 'N'
                "
                @click="playerCard"
                :title="tabledata.member_no"
                :class="{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down',
                }"
              >

                  {{ tabledata.playing_name }}
                  <span v-if="tabledata.sponsored === 'Y'"
                    ><b-img :src="config.VUE_APP_TITLEIST"></b-img
                  ></span>

              </td>
              <td
                v-if="
                  tabledata.playerno !== 'Cat' &&
                    tabledata.pos !== 'CUT' &&
                    team === 'Y'
                "
                @click="playerCard"
                :title="tabledata.member_no"
                :class="{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down',
                }"
              >
                {{ tabledata.name }}
                <span v-if="tabledata.sponsored === 'Y'"
                  ><b-img :src="config.VUE_APP_TITLEIST"></b-img
                ></span>
              </td>
              <td class="mobHide" v-if="tabledata.pos !== 'CUT'">
                <span v-if="tabledata.nationality === ''"></span>
                <span v-else>
                  <img
                    class="flag"
                    :src="
                      (config.VUE_APP_FLAG_URL + tabledata.nationality)
                        | lowercase
                    "
                  />
                </span>
              </td>
              <td v-if="tabledata.vspar < 0" class="up">
                {{ tabledata.vspar }}
              </td>
              <td v-else-if="tabledata.vspar > 0" class="down">
                {{ tabledata.vspar }}
              </td>
              <td v-else-if="tabledata.pos !== 'CUT'">
                {{ tabledata.vspar }}
              </td>
              <template v-if="tablePick === 'Score' && tabledata.pos !== 'CUT'">
                <td
                  v-for="(i, roundIt) in range(1, roundsPlayed)"
                  :key="roundIt"
                >
                  <span
                    v-if="roundsPlayed === 1 && tabledata.vspar < 0 && tabledata.score_found === 'N'"
                    class="up"
                    >{{ tabledata["score_R" + data.round] }}</span
                  >
                  <span
                    v-else-if="roundsPlayed === 1 && tabledata.vspar > 0 && tabledata.score_found === 'N'"
                    class="down"
                    >{{ tabledata["score_R" + data.round] }}</span
                  >
                  <span
                    v-if="roundsPlayed === 1 && tabledata.vspar < 0 && tabledata.score_found === 'Y'"
                    class="up"
                    >{{ tabledata.score}}</span
                  >
                  <span
                    v-else-if="roundsPlayed === 1 && tabledata.vspar > 0 && tabledata.score_found === 'Y'"
                    class="down"
                    >{{ tabledata.score}}</span
                  >
                  <span v-else-if="roundsPlayed === 1">{{
                    tabledata.score
                  }}</span>
                  <span v-else-if="tabledata['vspar_R' + i] < 0" :class="up">{{
                    tabledata["score_R" + i]
                  }}</span>
                  <span
                    v-else-if="tabledata['vspar_R' + i] > 0"
                    :class="down"
                    >{{ tabledata["score_R" + i] }}</span
                  >
                  <span v-else>{{ tabledata["score_R" + i] }}</span>
                </td>
                <td v-if="tabledata.vspar < 0 && roundsPlayed > 1" class="up">
                  {{ tabledata.score }}
                </td>
                <td
                  v-else-if="tabledata.vspar > 0 && roundsPlayed > 1"
                  class="down"
                >
                  {{ tabledata.score }}
                </td>
                <td v-else-if="roundsPlayed > 1">
                  {{ tabledata.score }}
                </td>
              </template>
              <template v-if="tablePick === 'Live' && tabledata.pos !== 'CUT'">
                <td v-if="tabledata.vspar < 0" class="up">
                  <span v-if="tabledata.holes === ''" style="color:black;">
                    {{ tabledata["time_R" + data.pft_round] }}
                  </span>
                  <span v-if="tabledata.holes < 18">
                    {{ tabledata.holes
                    }}<span v-if="tabledata['tee_R' + roundsPlayed] === '10'"
                      >*</span
                    >
                  </span>
                  <span v-else>
                    F<span v-if="tabledata['tee_R' + roundsPlayed] === '10'"
                      >*</span
                    >
                  </span>
                </td>
                <td v-else-if="tabledata.vspar > 0" class="down">
                  <span v-if="tabledata.holes < 18">
                    {{ tabledata.holes
                    }}<span v-if="tabledata['tee_R' + roundsPlayed] === '10'"
                      >*</span
                    >
                  </span>
                  <span v-else>
                    F<span v-if="tabledata['tee_R' + roundsPlayed] === '10'"
                      >*</span
                    >
                  </span>
                </td>
                <td v-else>
                  <span v-if="tabledata.holes < 18">
                    {{ tabledata.holes
                    }}<span v-if="tabledata['tee_R' + roundsPlayed] === '10'"
                      >*</span
                    >
                  </span>
                  <span v-else>
                    F<span v-if="tabledata['tee_R' + roundsPlayed] === '10'"
                      >*</span
                    >
                  </span>
                </td>
              </template>
              <td
                v-if="
                  tabledata.playerno !== 'Cat' &&
                    tabledata.playerno !== 'EOF' &&
                    config.VUE_APP_PLAYER_ICON === 'Y'
                "
              >
                <a :href="'/#/playerprofile/' + tabledata.member_no">
                  <font-awesome-icon
                    style="color:#337ab7!important;"
                    id="user"
                    class="icon"
                    :icon="['fa', 'user']"
                  />
                </a>
              </td>
            </template>
            <template
              v-if="
                (tablePick === 'Score' && data.match_play === 'Y') ||
                  (tablePick === 'Final' && data.match_play === 'Y')
              "
            >
              <td
                :title="tabledata.member_no_1"
                :class="{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down',
                  win: tabledata.status_1 == 'W',
                }"
              >
                {{ tabledata.initials_1 }} {{ tabledata.surname_1 }}
              </td>
              <td v-if="tabledata.status_1 === 'W'" class="win">
                {{ tabledata.full_score_1 }}
              </td>
              <td v-else></td>
              <td>
                <span v-if="tabledata.holes === ''">
                  {{ tabledata.time }}
                </span>
                <span v-else-if="tabledata.holes < 18">
                  {{ tabledata.holes
                  }}<span v-if="tabledata.tee_R1 > 1">*</span>
                </span>
                <span v-else>
                  F<span v-if="tabledata.tee_R1 > 1">*</span>
                </span>
              </td>
              <td v-if="tabledata.status_2 === 'W'" class="win">
                {{ tabledata.full_score_2 }}
              </td>
              <td v-else></td>
              <td
                :title="tabledata.member_no_2"
                :class="{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down',
                  win: tabledata.status_2 == 'W',
                }"
              >
                {{ tabledata.initials_2 }} {{ tabledata.surname_2 }}
              </td>
            </template>
            <template v-if="tablePick === 'Final'">
              <td
                class="posnum"
                v-if="
                  index !== 0 &&
                    (tabledata.tied !== '=' ||
                      Eds[index - 1].pos !== tabledata.pos)
                "
              >
                <span>
                  {{ tabledata.pos }}
                </span>
              </td>
              <td class="posnum" v-else-if="index === 0">
                <span>
                  {{ tabledata.pos }}
                </span>
              </td>
              <td v-else class="noPos"><span>.</span></td>

              <td
                v-if="tabledata.playerno !== 'Cat' && tabledata.pos !== 'CUT'"
                @click="playerCard"
                :title="tabledata.member_no"
                :class="{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down',
                }"
              >

                  {{ tabledata.playing_name }}
                  <span v-if="tabledata.sponsored === 'Y'"
                    ><b-img :src="config.VUE_APP_TITLEIST"></b-img
                  ></span>

              </td>
              <td class="mobHide" v-if="tabledata.pos !== 'CUT'">
                <span v-if="tabledata.nationality === ''"></span>
                <span v-else>
                  <img
                    class="flag"
                    :src="
                      (config.VUE_APP_FLAG_URL + tabledata.nationality)
                        | lowercase
                    "
                  />
                </span>
              </td>
              <td>
                {{ tabledata.winnings }}
              </td>
              <template v-if="match === 'N'">
                <td v-if="tabledata.vspar < 0" class="up">
                  {{ tabledata.vspar }}
                </td>
                <td v-else-if="tabledata.vspar > 0" class="down">
                  {{ tabledata.vspar }}
                </td>
                <td v-else-if="tabledata.pos !== 'CUT'">
                  {{ tabledata.vspar }}
                </td>
                <td v-if="tabledata.vspar < 0 && roundsPlayed > 1" class="up">
                  {{ tabledata.score }}
                </td>
                <td
                  v-else-if="tabledata.vspar > 0 && roundsPlayed > 1"
                  class="down"
                >
                  {{ tabledata.score }}
                </td>
                <td v-else-if="roundsPlayed > 1">
                  {{ tabledata.score }}
                </td>
                <td
                  v-for="(i, roundIt) in range(1, roundsPlayed)"
                  :key="roundIt"
                >
                  <span
                    v-if="roundsPlayed === 1 && tabledata.vspar < 0"
                    class="up"
                    >{{ tabledata.score }}</span
                  >
                  <span
                    v-else-if="roundsPlayed === 1 && tabledata.vspar > 0"
                    class="down"
                    >{{ tabledata.score }}</span
                  >
                  <span v-else-if="roundsPlayed === 1">{{
                    tabledata.score
                  }}</span>
                  <span v-else-if="tabledata['vspar_R' + i] < 0" :class="up">{{
                    tabledata["score_R" + i]
                  }}</span>
                  <span
                    v-else-if="tabledata['vspar_R' + i] > 0"
                    :class="down"
                    >{{ tabledata["score_R" + i] }}</span
                  >
                  <span v-else>{{ tabledata["score_R" + i] }}</span>
                </td>
              </template>
              <td
                v-if="
                  tabledata.playerno !== 'Cat' &&
                    tabledata.playerno !== 'EOF' &&
                    config.VUE_APP_PLAYER_ICON === 'Y'
                "
              >
                <a :href="'/#/playerprofile/' + tabledata.playerno">
                  <font-awesome-icon
                    style="color:#337ab7!important;"
                    id="user"
                    class="icon"
                    :icon="['fa', 'user']"
                  />
                </a>
              </td>
            </template>
          </tr>
          <tr
            @click.stop="rowClicked(rowClicked === -1)"
            v-if="index === clickedRow"
            class="tr-kids"
            :key="index + Math.random()"
          >
            <td id="reportCard" colspan="11">
              <PlayerReportCard
                :data="playerCardData"
                :config="config"
                :actualRound="data.pft_round"
                :roundsPlayed="data.pft_round"
              />
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import PlayerReportCard from "@/components/playerReportCard.vue";
export default {
  name: "reportTable",
  props: ["data", "title", "Eds", "id", 'config', 'team', 'match'],
    components: { PlayerReportCard },
  data() {
    return {
      up: "up",
      down: "down",
      scores: [],
      clickedRow: -1,
      cardID: [],
      playerCardData: [],
    };
  },
  methods: {
    range: function(start, end) {
      if (this.roundsPlayed === 1) return this.roundsPlayed;
      else
        return Array(end - start + 1)
          .fill()
          .map((_, idx) => start + idx);
    },
    rowClicked: function(index) {
      if (this.tablePick === "Score" && this.clickedRow !== index)
        this.clickedRow = index;
      else this.clickedRow = -1;
    },
    playerCard: function(event) {
      var cardID = event.target.getAttribute("title");
      return (
        (this.cardID = cardID),
        axios
          .get(
            process.env.VUE_APP_API+"inc/xsjs.php?type=xml2json&url=https://faldoseries.info/js/xml2json.php?url=/wp-content/themes/faldo/inc/xsjs.php?type=xml2json&url=https://faldoseries.info/tic/tmcard.cgi?jsout=x~xsl=~tourn=" +
              this.data.code +
              "~player=" +
              this.cardID +
              "104089~?randomadd=" +
              new Date().getTime()
          )
          .then((response) => (this.playerCardData = response.data))
      );
    },
  },
  computed: {
    tablePick: function(picked) {
      if (this.title === "Tournament Entries") picked = "Entries";
      else if (this.title.includes("Draw")) picked = "Draw";
      else if (
        this.title.includes("Scores") ||
        this.title.includes("Scoreboard") 
      )
        picked = "Score";
      else if (this.title.includes("Final")) picked = "Final";
      else if (this.title.includes("Live")) picked = "Live";
      return picked;
    },
    roundsPlayed() {
      return parseInt(this.data.rounds_played);
    },
  },
  watch: {
    Eds(newValue, oldValue) {
      newValue.forEach((value1, key1) => {
        oldValue.forEach((value2, key2) => {
          if (value1.id === value2.id) {
            if (key1 < key2) {
              value1.posChang = "up";
            } else if (key1 > key2) {
              value1.posChang = "down";
            }
          }
        });
      });

      console.log(newValue);
    },
    immediate: true,
    deep: true,
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    },
  },
};
</script>

<style scoped>
.tourHead {
  text-align: center;
  color: #004812;
  margin-top: 8px;
  margin-bottom: 0px;
  font-size: 24px;
  padding-top: 10px;
}
.tourDates {
  text-align: center;
  color: #004812;
  margin-top: 8px;
  margin-bottom: 0px;
  font-size: 16px !important;
}
.mainTitle {
  background-color: #fff;
}
.posnum {
  padding: 5px;
  background-color: #004812;
  color: #ffffff;
  padding-top: 4px;
  padding-bottom: 4px;
  display: block;
  max-width: 58px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1px;
  margin-bottom: 1px;
}
.drawPos {
  max-width: 100% !important;
  text-align: -webkit-center !important;
  margin-top: 0px !important;
}
.catD {
  background-color: #860038 !important;
  color: #ffffff;
  text-align: center;
  margin-top: 0px !important;
  height: 39px;
  padding-top: 9px;
  border-top: none !important;
  font-size: 19px !important;
}
th {
  position: sticky;
  top: 0;
  background-color: #004812 !important;
  color: white !important;
}
table {
  width: 100%;
}
thead > tr {
  color: white;
}
td:first-child {
  text-align: center;
}
th:first-child {
  text-align: center;
}
.drawName {
  font-size: 14px !important;
}
.odd {
  background-color: white !important;
}
.even {
  background-color: #dfe1e6 !important;
}
tr:nth-child(even) {
  background-color: #dfe1e6;
}
td {
  padding-top: 6px !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
  padding-bottom: 6px !important;
  font-size: 19px !important;
  vertical-align: middle;
  padding-left: 8px !important;
}

.noPos > span {
  color: transparent;
  padding: 5px;
  display: block;
  max-width: 58px;
}
.flag {
  width: 30px;
  box-shadow: 0 0 3px #555;
}
.up {
  color: #a52a2a;
}
.down {
  color: #1e90ff;
}
.text-down {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: glow-red 2s infinite;
  animation-iteration-count: 6;
}
.win {
  background-color: #0471b8 !important;
  color: #fff !important;
  border-bottom: 1px solid #ffff;
  margin-bottom: -1px;
}
@keyframes glow-red {
  to {
    text-shadow: 0 0 10px rgba(255, 82, 82, 0.7);
  }
}

.text-up {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: glow-green 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-green {
  to {
    text-shadow: 0 0 10px rgba(51, 217, 178, 0.7);
  }
}
.cut {
  padding-top: 6px !important;
  padding-right: 6px !important;
  padding-bottom: 6px !important;
  font-size: 19px !important;
  vertical-align: middle;
  padding-left: 8px !important;
  background: #aa0003;
  color: white;
}
@media only screen and (max-width: 768px) {
  .title > h3 {
    font-size: 13px !important;
  }
  .title > p {
    font-size: 12px !important;
  }
  th {
    font-size: 13px !important;
  }
  td {
    font-size: 13px !important;
  }
}
</style>
