<template>
  <div>
    <div class="contentOuterBox">
      <div class="contentInnerBox">
        <span v-html="wpPage.content.rendered"></span>
      </div>
    </div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "single",
  data() {
    return {
      wpPage: [],
    };
  },
  mounted() {
    const json = "pages/" + this.$route.query.wppage
     apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.wpPage = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
.contentOuterBox {
  padding: 20px;
}
.contentInnerBox {
  background-color: white;
  border: 1px solid #e1e1e1;
  padding: 20px;
}
</style>
