<template>
  <div>
    <div class="drop">
      <b-nav card-header pills>
        <b-dropdown
          v-if="Array.isArray(tmparams.reports.reports_entry)"
          :text="title"
          class="dropdownReports"
        >
          <b-dropdown-item
            v-on:click="changeReport"
            v-for="(reports, index) in tmparams.reports.reports_entry"
            :key="index"
            :title="reports.report_url"
            :value="reports.report_title"
            class="option"
            >{{ reports.report_title }}</b-dropdown-item
          >
        </b-dropdown>
        <b-dropdown
          v-else
          text="Please Select a Report"
          class="dropdownReports"
        >
          <b-dropdown-item
            v-on:click="changeReport"
            v-for="(reports, index) in tmparams.reports"
            :key="index"
            :text="title"
            class="option"
            :value="reports.report_title"
            >{{ reports.report_title }}</b-dropdown-item
          >
        </b-dropdown>
        <b-button onclick="window.print()" class="print">Print</b-button>
      </b-nav>
    </div>
    <div class="reports">
      <ReportTable
        :data="data"
        :Eds="Eds"
        :title="title"
        :id="this.$route.query.id"
        :config="config"
        :team="teamEvent"
        :match="tmparams.match_play"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ReportTable from "@/components/reportTable.vue";

export default {
  name: "Reports",
  props: ["config"],
  components: {
    ReportTable,
  },
  data() {
    return {
      data: [],
      tmparams: [],
      id: this.$route.query.id,
      currentReport: this.$route.query.url,
      title: this.$route.query.title,
    };
  },
  methods: {
    changeReport: function(event) {
      var report = event.target.getAttribute("title");
      var title = event.target.getAttribute("value");
      this.title = title;
      return (
        (this.currentReport = report),
        axios
          .get(
            process.env.VUE_APP_API +
              "inc/xsjs.php?type=xml2json&url=" +
              report +
              "~enhanced=Y~exemptorder=F~jsout=x~xsl~?randomadd=" +
              new Date().getTime()
          )
          .then((response) => (this.data = response.data))
      );
    },
    updateStuff: function() {
      axios
        .get(this.reportTitle)
        .then((response) => {
          this.data = response.data;
          return axios.get(
            process.env.VUE_APP_API +
              "inc/xsjs.php?type=xml2json&url=" +
              this.currentReport +
              "~enhanced=Y~exemptorder=F~jsout=x~xsl~?randomadd=" +
              new Date().getTime()
          );
        })
        .then((response) => {
          this.data = response.data;
        });
    },
  },
  computed: {
    Eds(reportData) {
      if (this.title === "Tournament Entries")
        reportData = this.data.entries.entries_entry;
      else if (this.title.includes("Draw"))
        reportData = this.data.draw.draw_entry;
      else if (
        this.title.includes("Scores") ||
        this.title.includes("Scoreboard") ||
        this.title.includes("Live")
      )
        reportData = this.data.scores.scores_entry;
      else if (this.title.includes("Final"))
        reportData = this.data.result.result_entry;
      return reportData;
    },
    teamEvent: function(team) {
      var array = this.data;
      if (array["team_scores"] !== undefined) team = "Y";
      else team = "N";
      return team;
    },
  },
  created() {
    setInterval(this.updateStuff, 120000);
  },

  mounted() {
    axios
      .get(
        process.env.VUE_APP_API +
          "inc/xsjs.php?type=xml2json&url=" +
          this.currentReport +
          "~enhanced=Y~exemptorder=F~jsout=x~xsl~?randomadd=" +
          new Date().getTime()
      )
      .then((response) => {
        this.data = response.data;
        return axios.get(
          process.env.VUE_APP_API +
            "inc/xsjs.php?type=xml2json&url=" +
            process.env.VUE_APP_TIC_BASE +
            "tourn=" +
            this.$route.query.code +
            "~jsout=x~xsl~?randomadd=" +
            new Date().getTime()
        );
      })
      .then((response) => {
        this.tmparams = response.data;
      });
  },
};
</script>

<style scoped>
.dropdownReports > button:hover {
  background-color: pink !important;
}
::v-deep tr:nth-child(even) {
  background-color: #dfe1e6;
}
.drop {
  padding: 25px;
}
.option {
  font-weight: normal;
  font-size: 14px;
  display: block;
  white-space: nowrap;
  min-height: 1.2em;
  padding: 0px 2px 1px;
}
::v-deep button {
  font-size: 15px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #004812;
  color: #ffffff;
  border-radius: 0px;
  border: 0px;
  height: 30px;
  width: 100%;
  text-align: left;
}
::v-deep button::after {
  float: right;
  margin-top: 8px;
}
.dropdown {
  width: 25%;
  padding-right: 15px;
}
.print {
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.428571;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #fff;
  background-color: #860038;
  border-color: #860038;
  width: 60px;
}
::v-deep button:hover {
  color: #860038;
  background-color: #fff;
}
::v-deep .nav-link.active {
  color: white !important;
  background-color: #004812 !important;
  width: 100%;
  border-color: #004812 !important;
  height: 100%;
}

::v-deep .nav-link {
  color: black !important;
  width: 100%;
  font-size: 18pt;
}

::v-deep li.nav-item {
  width: 50%;
  text-align: center;
}
::v-deep .mt-3 {
  margin-top: 0 !important;
}
@media only screen and (max-width: 768px) {
  .mobHide {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .sponsor {
    margin-top: 2%;
    width: 35%;
    padding-left: 4%;
  }
  .dropdown {
    width: 60%;
  }
}
</style>
